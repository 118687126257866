import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {LoginGuard} from '@synisys/idm-ui-shell';
import {LoginPagePreventionGuard} from '@synisys/idm-ui-shell/app/services/login-page-prevention-guard';

const appRouting: Routes = [
	{
		path: 'login',
		canActivate: [LoginPagePreventionGuard],
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./login/login.module')['LoginModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'LoginModule');}) },
	},
	{
		path: 'registration',
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('@synisys/idm-user-data-ui/app/registration/registration.module')['RegistrationModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'RegistrationModule');}) },
	},
	{
		path: 'resetpassword',
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('@synisys/idm-user-data-ui/app/resetPassword/reset-password.module')['ResetPasswordModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'ResetPasswordModule');}) },
	},
	{
		path: 'expiredpassword',
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('@synisys/idm-user-data-ui/app/expired-password/expired-password.module')['ExpiredPasswordModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'ExpiredPasswordModule');}) },
	},
	{
		path: 'ssoprelogin',
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('@synisys/idm-ui-shell/app/modules/sso-pre-login/sso-pre-login.module')['SSOPreLoginModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
	},
	{
		path: '',
		canLoad: [LoginGuard],
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./shell/main.shell.module')['MainShellModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'MainShellModule');}) }
	},
	{
		path: 'shared',
		loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('@synisys/idm-page-controls/app/controls/page-management/published-page/published-page.module')['PublishedPageModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
	}
];

const initialRouteOptions: ExtraOptions = {
	onSameUrlNavigation: 'reload'
};

export const appRoute: ModuleWithProviders = RouterModule.forRoot(appRouting, initialRouteOptions); // {preloadingStrategy: PreloadAllModules}
